.react-datepicker {
  background: #ffffff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 0;
}

.react-datepicker__input-container input {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 3px;
  padding-left: 15px;
  border: 0 !important;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  border: 0;
  background-color: #fff;
}

.react-datepicker__month-container {
  padding: 5px;
}

.react-datepicker__current-month {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
}

.react-datepicker__navigation {
  top: 17px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--previous:hover {
  border-right-color: #0068ff;
}

.react-datepicker__navigation--previous:focus {
  outline: 0;
}

.react-datepicker__navigation--next,
.react-datepicker__navigation--next:hover {
  border-left-color: #0068ff;
}

.react-datepicker__navigation--next:focus {
  outline: 0;
}

.react-datepicker__day-name {
  color: transparent;
}

.react-datepicker__day-name:first-letter {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #999999;
}

.react-datepicker__day-name,
.react-datepicker__day {
  height: 24px;
  width: 24px;
  padding: 6px 3px 0px 3px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #444444;
  background: unset;
}

.react-datepicker__day--selected {
  background: #1175fb;
  border-radius: 4px;
  color: #ffffff;
}

.react-datepicker__day--outside-month {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #999999;
}
