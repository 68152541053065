.dropdown {
  margin-top: 10px;
  width: 100%;
  /*border-radius: 10px;*/
  border: 0.5px solid #b3b3b3;
  background-color: white;
}

.dropdown-header {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-body {
  padding: 5px;
  border-top: 1px solid #e5e8ec;
  background-color: #ffffff;
  display: none;
}

.dropdown-body.open {
  display: block;
}

.dropdown-item {
  padding: 10px;
}

.dropdown-item:hover {
  cursor: pointer;
  background-color: rgb(49, 118, 242);
  color: #ffffff;
}

.dropdown-item-dot {
  opacity: 0;
  color: #91a5be;
  transition: all 0.2s ease-in-out;
}

.dropdown-item-dot.selected {
  opacity: 1;
}

.icon {
  font-size: 13px;
  color: rgb(49, 118, 242); /* #91a5be */
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}

.icon.open {
  transform: rotate(180deg);
}
